<template>
    <div class="moon-container">
        <div class="circle1"></div>
        <div class="circle2"></div>
        <div class="moon">
             <div class="text">{{phaseName}}</div>
             <div class="image-and-text">
                <img :src="moonIcon" /> 
                <div class="percentage">
                    <span class="label">%</span><span>{{Math.round(percentage)}}</span>
                </div>
            </div>
           
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default{
     data(){
        return{
            moonIcon:String,
            percentage:String,
            phaseName:String,
        }
    },
    props:{
        moon:{} 
    },
    methods: {
        getMoonData(){
            axios
            .get('https://aainteractive.no/tibber-v2/cache/?name=moon')
            .then(response => {
                console.log(response)
                let moon = response.data
                /*
                    let symbolCode = response.data.predictions[0].data.next_1_hours.summary.symbol_code;
                    this.weatherIcon = require('@/assets/weather_icons/svg/'+(symbolCode)+'.svg')
                */
                this.phaseName = this.getMoonPhase(moon.moonphase["@attributes"].value);
                this.percentage = moon.moonphase["@attributes"].value;    
                this.moonIcon = require('@/assets/moons/'+(this.getMoonIcon(this.percentage))+'.svg')
            })
        },
       getMoonPhase(val){
            let phase = "";

            if(val > 0 && val <= 25){
                phase = 'Voksende månesigd'; //waxing crescent
            }
            else if(val > 25 && val <= 50){
                phase = 'Voksende måne'; //waxing gibbous
            }
            else if(val > 50 && val < 75){
                phase = 'Minkende måne'; //waning gibbous
            }
            else if(val > 75 && val <= 100){
                phase = 'Minkende månesigd'; //waning crescent
            }
            return phase;
       },
       getMoonIcon(val){ //kan utvides til 8 ikoner!
            let icon = '0';

            if(val > 0 && val <= 25){
                icon = '2';
            }
            else if(val > 25 && val <= 50){
                 icon = '4';
            }
            else if(val > 50 && val < 75){
                 icon = '6';
            }
            else if(val > 75 && val <= 100){
                icon = '8';
            }
            return icon;
       }
    },
    watch: {
         moon:function(){
            
         }
    },
     mounted () {
        this.getMoonData();
    }
}
</script>
<style scoped>
.moon-container{
    position:relative;
    color:#3C3C3C;
}
.circle1{
    position:absolute;
    width:400px;
    height:400px;
    background:#F2EDCE;
    z-index: 50;
    border-radius:400px;
    left:-170px;
    top:-240px;
}
.circle2{
    position:absolute;
    width:420px;
    height:420px;
    border:4px solid #F2EDCE;
    z-index: 50;
    border-radius:400px;
    left: -181px;
    top: -249px;
}
.moon{
    position:relative;
    z-index:100;
}
.moon .text{
    font-size:23px;
    text-transform: uppercase;
}
.moon .percentage{
    font-size:45px;
    margin-top:5px;
}
.moon img{
    width:50px;
}
.image-and-text{
    display:flex;
    align-items: center;
}
.label{
    font-size: 26px;
    padding:0px 5px 0 10px;
}
</style>
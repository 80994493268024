<template>

        <div class="universe">   
            <div class="landscape">
                    <Sun  />
                    <div class="background">
                        <div class="globe">
                            <div class="globe-outer">  
                                <div class="globe-labels">
                                    <GlobeLabels  />
                                </div>
                            </div>
                            <div class="globe-inner">
                                <div class="horizon"></div>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div class="foreground">
                            
                        <!-- ABOVE HORIZON -->
                        <div class="above-horizon">
                            <div class="relative">
                                    
                                <div class="house-container">
                                    <div class="house">
                                        <img src="@/assets/home2.svg" />
                                    </div>
                                </div>

                                 <span style="position:absolute;left:615px;top:80px">
                                    <!---->  <!--BRUK FLEX CENTER?????-->
                                    <WeatherSmall :weather="weather" />
                                </span>

                                <span style="position:absolute;left:180px;bottom:-11px;z-index:10">
                                    <Garbage  />
                                </span>
                            
                                <span style="position:absolute;left:310px;bottom:143px">
                                    <!-- Hvordan få STABIL som ikke flytter seg?-->
                                    <PowerSubscription  />
                                </span>

                                <div style="position:absolute;left:890px;bottom:40px">
                                    <Netatmo />
                                </div>
                        
                                <span style="position:absolute;right:90px;bottom:0px;overflow:hidden">
                                    <!----><!--1) Advarsel om sterk vind-->
                                    <Wind :weather="weather" />
                                </span>

                            </div>
                        </div>
                        
                        <!-- BELOW HORIZON -->
                        <div class="below-horizon">
                            <div class="relative">
                                <div class="swim">
                                    <Swim />
                                </div>
                            </div>
                        </div>                                           
                    </div>
            </div>
        </div>

</template>
<script>

import Sun from '@/components/units/Sun.vue';
import PowerSubscription from '@/components/units/PowerSubscription.vue';
import WeatherSmall from '@/components/units/WeatherSmall.vue';
import Wind from '@/components/units/Wind.vue';
import Garbage from '@/components/units/Garbage.vue';
import GlobeLabels from '@/components/units/GlobeLabels.vue';
import Netatmo from '@/components/units/Netatmo.vue';
import Swim from '@/components/units/Swim.vue';

export default{
     components: {
        PowerSubscription,
        WeatherSmall,
        Wind,
        Sun,
        Netatmo,
        Garbage,
        GlobeLabels,
        Swim
      },
    props:{
        weather:{}
    }
}
</script>
<style scoped>
    .universe{
        padding-top:50px;
        height: 900px;
        
        position:relative;
        display:flex;
        justify-content: space-around;
        overflow:hidden;
    }
    .universe .landscape{
        position:absolute;
        height:100%;
        width:1360px;
    }
    .landscape .background{
        height:100%;
        width: 100%;
        position:absolute;
        z-index:10;
        display:flex;
        justify-content: space-around;
        align-items: flex-start;
    }
    .landscape .foreground{
        position:relative;
        z-index:50;
        height:100%;
    }
    .landscape .foreground .above-horizon{
        height:680px;
        width:100%;
      
        top:0;
        left:0;
    }
    .landscape .foreground .below-horizon{
        position:absolute;
        bottom:0;
        left:0;
        height:138px;
        width:100%;
       
    }
    .landscape .globe{
        
        width:100%;
        height: 100%; 
        position:relative; 
    }   
     .landscape .globe .globe-outer{
      /*  background:yellow;*/
        width:1360px;
        height:1360px;
        border-radius:1360px;
        position:absolute;
        z-index:210;
        top:0;
        left:0;
        
        border:1px solid #C3BFAB;
    }
    .landscape .globe .globe-labels{
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        height:100%;
        
        z-index: 220;
    }
     .landscape .globe .globe-inner{
        border:1px solid #C3BFAB;
        width:1350px;
        height:1350px;
        border-radius:1350px;
        position:absolute;
        z-index:200;
        left:5px;
        top:5px;

        display:flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .landscape .globe .horizon{
        height:1px;
        background:#C3BFAB;
        width:100%;
    }
    .relative{
        position:relative;height:100%;width:100%;
    }

     .house-container{
        position: absolute;
        width:100%;
        height:100%;
        bottom:0;

        display:flex;
        justify-content: space-around;
        align-items: flex-end;
    }
   .house{
        width:338.32px;
        height: 414.83px;
        margin-bottom: -1px;
    }
    .section{
        position: relative;
        height: 200px;
    }
    .section .labels{
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height: 200px;
      
      padding:6px 0;

      display:flex;
      justify-content: space-between;
    }

    .swim{
        position:absolute;
        right: 6px;
        bottom: 121px; 
    }

</style>
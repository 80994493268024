<template>
    <div class="table-wrapper">
        <div class="table">
            <div class="left">
                <div class="max">Kr {{parseFloat((prices.today.max)).toFixed(2)}}</div>
                <div class="min">Kr {{parseFloat((prices.today.min)).toFixed(2)}}</div>
            </div>
            <div class="right">
            <div class="header">
                <div v-for="header in table.headers" :key="header.id" class="label">
                    {{header.time}}
                </div>
            </div>
            <div class="body">
                <div v-for="price in prices.today.hour_by_hour" :key="price.id" :class="getClass(price)">
                    <div class="indicator" :style="getPos(price.total)">
                        <span class="bg"></span>
                        <span class="fg"></span>
                        <div class="price-indicator">
                            <div class="price">
                                <Price :leftArrow = "true" :darkMode = "true" :price="prices.current" /> 
                                <div class="arrow"></div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="curve">
                    <svg viewBox="0 0 240 50" >
                        <path stroke="#3c3c3c" stroke-width=".8" fill="none" :d="svg.path.value" />
                    </svg>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import Price from '@/components/util/Price.vue';
export default{
     data(){
        return{
            prices:{
                current:0.0,
                today:{
                    min:0.0,
                    max:0.0,
                    hour_by_hour:{}
                }
            },
            table:{
                height:275,
                headers:[{time:'00:00'},{time:'02:00'},{time:'04:00'},{time:'06:00'},{time:'08:00'},{time:'10:00'},{time:'12:00'},{time:'14:00'},{time:'16:00'},{time:'18:00'},{time:'20:00'},{time:'22:00'}]
            },
            svg:{
                scale:'48', //oppløsning i høyden
                path:{
                    start:'M5', //5 ut fra siden
                    startAdjustment:1, //pluss drar grafen oppover
                    steps: 0.5,
                    value:"M0 0"
                },
            },
            time:{
                current:{
                    hour:0
                }
            }  
        }
    },
    components: {
        Price
    },
    props:{
        power:{}
    },
    methods:{
        getPos(price){
            let scale = this.prices.today.max - this.prices.today.min;
            let diff = this.prices.today.max - price;
            let px = (diff / scale) * (this.table.height-10);
            
            return "margin-top:"+px+"px";
        },
        getClass(price){
           
            let hour = moment(price.startsAt).format('H'); 
           
            if((hour) == this.time.current.hour){
                 return "hour current";
            }
            return "hour";
        },
        getCurrentPricePos(price){
            let scale = this.prices.today.max - this.prices.today.min;
            let diff = this.prices.today.max - price;
            
            //let percentage = (diff / scale) * 100;
            //høyden minus elementet selv
            let px = (diff / scale) * (this.table.height-10);

            px = px -3; //alignment adjustment
            
            return "margin-top:"+px+"px";
        },
        createPath(){

            let penStart = this.svg.path.start +' ';
            //let pathLines = 'l10 1.57 l10 1.89 l10 2.63 l10 -0.17 l10 -4.4 l10 -4.26 l10 -2.81 l10 -34.8';
            let pathLines = '';

            let prev = 0;
            let scale = this.prices.today.max - this.prices.today.min;
            let scaleSvg = this.svg.scale;
            let i = 0;
           
            (this.prices.today.hour_by_hour).forEach((value) => {
                
                let hour_price = value.total;

                if(i != 0){
                    
                    let diff = prev - hour_price;
                    let percentageChange = (diff/scale) * 100;
                    let lineChange = percentageChange * (scaleSvg/100);
                    pathLines = pathLines + "l10 "+lineChange;
                }
                else{
                        let diff = this.prices.today.max - hour_price;
                        let percentageDifference = (diff/scale) * 100;
                        let lineChange = percentageDifference * (scaleSvg/100);
                        
                        lineChange = lineChange + this.svg.path.startAdjustment;
                        
                        penStart = penStart + ' '+lineChange;
                }
                prev = value.total;
                i++;
            });
            this.svg.path.value = penStart + ' ' + pathLines;
        },
        setCurrentHour(){
            this.time.current.hour = moment().format('H');
            //console.log("INTERVALL CALL - SETTING CURRENT OUR IN GRAPH")
            //TODO: MÅ HENTE NYE DATA HVER TIME
        }
    },
    watch: {
        power: function () {

            //Sette dagens 24 timepriser
            this.prices.today.hour_by_hour = this.power.prices.today; //0-23

            //Sette min/max pris
            let min = 0;
            let max = 0;

            (this.prices.today.hour_by_hour).forEach(function(hour) {
                if(min == 0 || hour.total < min ){
                    min = hour.total;
                }
                if(max == 0 || hour.total > max ){
                    max = hour.total;
                }
            });

            this.prices.today.max = max;
            this.prices.today.min = min;

            //Sette gjeldende pris
            this.prices.current = this.power.prices.current.total;

            //Lag SVG graf
            this.createPath();
        }
    },
    mounted () {
        //Sett gjeldende time
        
    },
    created() {
        this.time.current.hour = moment().format('H');
        setInterval(() => this.setCurrentHour(), 60000);
    }
}

</script>
<style scoped>
.price-indicator{
    position:absolute;
    left: 50px;
    top: -36px;
    z-index:700;
}
.price-indicator .price{
    position:relative;
}
.table-wrapper{
    display:flex;
    justify-content: space-around;
    position:relative;
}
.table{
    max-width: 1380px;
    width: 1380px;
    margin-top:60px;
    display:flex;

    max-width: 1320px;
    width: 1320px;
    
}
.table .left{
    width:60px;
    
    padding-top:30px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;

    width:0px;
    position:absolute;
    height:308px;
    width:50px;
    opacity:0.7;
}
.table .left .max,.table .left .min{
    text-transform: uppercase;
}
.table .right{
    flex:1;
}
.table .header{
    display:flex;
    
}
.table .header .label{
  flex:1;
  padding:0 0 10px 0;
}
.table .body{
    display:flex;
    height:275px;
    width:100%;
    position:relative;
}
.table .body .hour{
    border:1px dashed #C3C0A8;
    flex:1;
    position:relative;
    display:flex;
    justify-content: space-around;
}
.table .body .hour.current{
    background:#FFFDE3;
   /* background-image:url('~@/assets/current.svg');*/
}
.table .body .hour.current .indicator{
    display:block;
    z-index:800;
}
.table .body .hour .indicator{
    background:#3C3C3C;
    width:10px;
    height:10px;
    border-radius: 10px;
    position:absolute;
    z-index: 900;
    display:none;
}
.table .body .hour.current .indicator span.bg{
    background:#FFFDE3;
    display: block;
    width: 32px;
    height: 32px;
    border: 3px solid #3C3C3C;

    border-radius: 32px;
    z-index: 50;
    left: -10px;
    position: absolute;
    top: -11px;
}
.table .body .hour.current .indicator span.fg{
    background:#3C3C3C;
    display: block;
    width: 18px;
    height: 18px;

    border-radius: 18px;
    z-index: 50;
    left: -3px;
    position: absolute;
    top: -4px;
}
.curve{
    position:absolute;
    left:0;
    top:0;
    width:1320px;
    height:275px;
    z-index:200;
}
.price-now-line{
    width:1320px;
    height:1px;
    border-top:2px dashed #3C3C3C;
    left:-1325px;
    top:40px;
    
    position:absolute;
}
</style>
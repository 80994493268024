<template>
    <div class="outer-space">
        <div class="moon"> <Moon :moon="moon" /> </div>
        <div style="font-size:54px;text-align:right;color:#3C3C3C" class="time"> 
            <div style="font-size:26px;text-transform:uppercase">
                {{getDate()}}
            </div>
            <div class="clock">{{clock}}</div>
        </div>
    </div>
</template>
<script>
import Moon from '@/components/units/Moon.vue';
import moment from 'moment';
    export default{
        data(){
            return{
                variable: String,
                clock:''
            }
        },
        components: {
            Moon
        },
        props:{
            moon:{}
        },
        methods:{
            getTime(){
                this.clock = moment().format('HH:mm');
                //return moment().format('HH:mm');
            },
            getDate(){
                moment.locale('nb')
                return moment().format('dddd Do MMM');
            }
        },
        created() {
            this.clock = moment().format('HH:mm');
            setInterval(() => this.getTime(), 6000);
        }
    }
</script>
<style scoped>
    .outer-space{
        position:absolute;
        width:100%;
        display: flex;
        justify-content: space-between;
        padding:20px;
        top:0;   
    }
    .clock{
        margin-top: -10px;
    }
</style>




<template>
    <img :src="weatherIcon" /> 
</template>
<script>
import axios from 'axios'

export default{
    data(){
        return{
            weatherIcon: String
        }
    },
    created() {
        setInterval(() => this.getWeatherData(), 30000); //1000=sekund
    },
    mounted () {
        this.getWeatherData();
    },
    methods:{
        getWeatherData(){
            axios
            .get('https://aainteractive.no/tibber-v2/cache/?name=weather')
            .then(response => {
                let symbolCode = response.data.predictions[0].data.next_1_hours.summary.symbol_code;
                this.weatherIcon = require('@/assets/weather_icons/svg/'+(symbolCode)+'.svg')
            })
        }
    }
}
</script>
<style scoped>
img{
    width:126px;
}
</style>
<template>
    <div class="summarized">{{accumulatedConsumption}} kw / {{accumulatedCost}} kr</div>

     <div class="consumption">
        
        <div class="value">
            <div><span class="number">{{liveMeasurement}}</span><span class="label">kw</span></div>
        </div>
        <div class="image">
            <img src="@/assets/lightning.svg" />  
        </div>
        <div class="lines"></div>
    </div>
    
</template>
<script>
import gql from 'graphql-tag'

export default{
    apollo:{
          $subscribe: {
            liveMeasurement: {
            query: gql`subscription{
                    liveMeasurement(homeId:"8b3b66f1-4743-42d2-8612-32e13467ac10"){
                        timestamp
                        power
                        accumulatedConsumption
                        accumulatedCost
                        currency
                        minPower
                        averagePower
                        maxPower
                    }
                }`,
                result ({ data }) {
                    let num = data.liveMeasurement.power;

                    let divided = num / 1000;
                    let n = divided.toFixed(2);
                    
                    this.liveMeasurement =  n.replace('.',',') ;

                    let acc = parseFloat(data.liveMeasurement.accumulatedConsumption);
                    this.accumulatedConsumption = acc.toFixed(1);

                    let acco = parseFloat(data.liveMeasurement.accumulatedCost);
                    this.accumulatedCost = acco.toFixed(1);
                    
                },
            },
        },
    },
    data(){
        return{
            liveMeasurement: {},
            accumulatedConsumption:'',
            accumulatedCost:''
        }
    }
   
}
</script>
<style scoped>
.summarized{
    display:flex;
    justify-content: space-around;
}
    .consumption{
        font-size:58px;
        color:#3C3C3C;
        text-align: center;

        width:160px;
        position:relative;
    }
    .consumption .value{
        display:flex;
        justify-content: space-around;
        align-content: center;
        align-items: center;
        letter-spacing: 0.1px;
        padding-bottom: 5px;
        
    }
    span.number{
        text-align: right;
    }
    .consumption .image{
        display:flex;
        justify-content: space-around;
        align-content: center;
    }
    
    .consumption .value .label{    
        padding-left:5px;
        font-size:26px;
        margin-bottom:5px;
        line-height: 12px;
    }
    img{
        width: 65px;
    }
    .lines{
        
        width:125px;
        height:30px;
        position:absolute;
        bottom:-30px;
        right:-50px;
        border-left:1px dashed #3C3C3C;
        border-bottom:1px dashed #3C3C3C;
        opacity:0.7;
    }
</style>
<template>

    <!-- Label -->
    <div class="label one">
        <div class="relative">
            <div class="time">
                {{twilightEnd}}
            </div>
            <div class="desc">
                Skumring
            </div>
        </div>
    </div>
    <!-- Line -->
    <div class="line one">
        <div class="relative">
            <div class="line"></div>
        </div>
    </div>

    <!-- Label -->
    <div class="label two">
        <div class="relative">
            <div class="time">{{sunDown}}</div>
            <div class="desc">Solnedgang</div>
        </div>
    </div>
    <!-- Line -->
    <div class="line two">
        <div class="relative">
            <div class="line"></div>
        </div>
    </div>

    <!-- Label -->
    <div class="label three">
        <div class="relative">
            <div class="desc">
                <div>Zen</div><div>ith</div>
            </div>
            <div>
                <div>{{sunTop}}</div>
            </div>
        </div>
    </div>
    <!-- Line -->
    <div class="line three">
        <div class="relative">
            <div class="line"></div>
        </div>
    </div>

    <!-- Label -->
    <div class="label four inverse">
        <div class="relative"> 
            <div class="desc">
                Soloppgang
            </div>
            <div class="time">
                {{sunUp}}
            </div>
        </div>
    </div>
    <!-- Line -->
    <div class="line four">
        <div class="relative">
            <div class="line"></div>
        </div>
    </div>

    <!-- Label -->
    <div class="label five inverse">
        <div class="relative">
            <div class="desc">
                Morgengry
            </div>
            <div class="time">
                {{twilightStart}}
            </div>
        </div>
    </div>
    <!-- Line -->
    <div class="line five">
        <div class="relative">
            <div class="line"></div>
        </div>
    </div>

    <svg class="arrow one" xmlns="http://www.w3.org/2000/svg" width="24.075" height="12.038" viewBox="0 0 24.075 12.038">
        <path id="Path_800" data-name="Path 800" d="M22.072,12.514H4v3.009H22.072v4.514l6-6.019L22.072,8Z" transform="translate(-4 -8)" fill="#3c3c3c"/>
    </svg>

    <svg class="arrow two" xmlns="http://www.w3.org/2000/svg" width="24.075" height="12.038" viewBox="0 0 24.075 12.038">
        <path id="Path_800" data-name="Path 800" d="M22.072,12.514H4v3.009H22.072v4.514l6-6.019L22.072,8Z" transform="translate(-4 -8)" fill="#3c3c3c"/>
    </svg>

    <div class="hider-left"></div>
    <div class="hider-right"></div>

</template>


<script>
import moment from 'moment';
import axios from 'axios';
export default{   
        data(){
            return{
                sunUp:'00:00',
                sunDown:'00:00',
                sunTop:'00:00',
                twilightStart:'00:00',
                twilightEnd:'00:00'
            }
        },
      mounted () {
        axios
            .get('https://aainteractive.no/tibber-v2/cache/?name=sun')
            .then(response => {
                    //if(!response.data.sun_down){return;}

                    let su = (response.data.sun_up)["@attributes"].time;
                    let su_formatted  = moment(su).utcOffset('+0100').format('HH:mm')
                    this.sunUp = su_formatted;

                    let sd = (response.data.sun_down)["@attributes"].time;
                    let sd_formatted  = moment(sd).utcOffset('+0100').format('HH:mm')
                    this.sunDown = sd_formatted;

                    let st = (response.data.sun_top)["@attributes"].time;
                    let st_formatted  = moment(st).utcOffset('+0100').format('HH:mm')
                    this.sunTop = st_formatted;

                    let tb = response.data.sun_twilight_begin;
                    let tb_formatted  = moment(tb).utcOffset('+0100').format('HH:mm')
                    this.twilightStart = tb_formatted;

                    let te = response.data.sun_twilight_end;
                    let te_formatted  = moment(te).utcOffset('+0100').format('HH:mm')
                    this.twilightEnd = te_formatted;

            })
      },
      created(){
      
      }
}
</script>

<style scoped>
     /*LABELS*/ 
    
    .label{
        position:absolute;
    }
    .label .relative{
        display:flex;
    }
    .label .relative .time{
        font-size: 17px;
        position:absolute;
    }
    .label.inverse .relative .time{
        left:50px;
    }
    .label .relative .desc{
        left:45px;
        position:absolute;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .label.inverse .relative .desc{
        left:-45px;
    }

    .line{
        position: absolute;
    }
    .line .line{
        width:5px;
        height:2px;
        background:#3c3c3c;
    }


    /* SKUMRING */
    .label.one{
    left: -9px;
    top: 838px;
    transform: rotate(-99deg);
    }
    .line.one{
       left: 10px;
    top: 815px;
        transform: rotate(-15deg); 
    }
    .line.one .line{
        width:9px;
    }




    
    /* Solnedgang */ 
    .label.two{
        left: -25px;
        top: 694px;
        transform: rotate(-90deg);   
    }
    .line.two{
        left:00px;
        top:677px;
        transform: rotate(0deg); 
    }
    .label.two .time{
        transform: rotate(0deg);
    }
    .label.two .desc{
        transform: rotate(4deg);
        top: 3px;
    }



    /* Zenith */
    .label.three{
        left:653px;
        top:-47px;
    }
    .label.three .relative{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .label.three .desc{
           transform: rotate(0deg);
           display:flex;
           position:relative;
           left:auto;
    }
    .label.three .desc div:nth-child(1){
           transform: rotate(-3deg);
           padding-right:1px;
    }
    .label.three .desc div:nth-child(2){
           transform: rotate(3deg);
           padding-left:1px;
    }
    .label.three .relative > div{
        padding:0px;
        
    }
    .label.three .time{
        transform: rotate(0deg);
        line-height: 18px;
        position:relative;
    }
    .line.three{
        left:678px;
        top:0px;
        transform: rotate(90deg); 
    }

   

    /* Soloppgang */    
    .label.four{
        right: -26px;
        top: 611px;
        transform: rotate(-271deg);   
    }
    .line.four{
        right:5px;
        top:679px;
        transform: rotate(0deg); 
    }
     .globe-labels .label.four .desc{
            transform: rotate(-4deg);
        top: 3px;

    }
    .globe-labels .label.four .time{
        transform: rotate(0deg);
    }



    /* Morgengry */
    .label.five{
        right: -31px;
        top: 760px;
        transform: rotate(-255deg);   
    }
    .line.five{
        right:22px;
        top:815px;
        transform: rotate(15deg); 
    }
    .line.five .line{
        width:9px;
    }
      .globe-labels .label.five .desc{
          transform: rotate(-9deg);
    top: 8px;


    left: -39px;
    }
    .globe-labels .label.five .time{
        transform: rotate(0deg);
    }
    
    
    





    
    /*LABELS*/ 
    .label .time{
        transform: rotate(-2deg);
    }
    .label .desc{
        transform: rotate(2deg);
    }



   


    
    
    
  


    .globe-labels .arrow{
        position:absolute;
    }
    .globe-labels .arrow.one{
        left:15px;
        top:793px;
        transform: rotate(75deg);
    }
    .globe-labels .arrow.two{
        right:17px;
         top:795px;
        transform: rotate(-75deg);
    }
    

    .hider-left{
      width: 10px;
    height: 40px;
    background: #FFFAD8;
    position: absolute;
    left: 15px;
    top: 815px;
    transform: rotate(-17deg);

    }
    .hider-right{
  width: 10px;
    height: 40px;
    background: #FFFAD8;
    position: absolute;
    right: 17px;
    top: 818px;
    transform: rotate(17deg);
    }

</style>
<template>
<div  :class="{dark:darkMode}">
    <div :class="{left:leftArrow}" class="price">
         <div class="label-kr">
                    Kr
                </div>
                <div class="kr">
                    {{getKr(price)}}    
                </div>
                <div class="ore">
                    <div class="value">{{getOre(price)}} </div>
                    <div class="label">øre</div>
                </div>
        <div class="left-arrow"></div>  
    </div> 
     
    </div>
    
</template>

<script>
export default{
    data()
    {
        return{
            kr:'',
            ore:'',
        }
    },
    props:{
        price:Number,
        darkMode:Boolean,
        leftArrow:Boolean
    },
     watch: {
            price: function () {
               // console.log("heheheehe")
               // console.log(this.price)
                //let number = ((this.price).toString()).split('.');
                
                //this.kr = number[0];
                //this.ore = number[1].charAt(0) + number[1].charAt(1);;
            }
        },
        methods:{
            getKr(price){
                //console.log("HVA ER PRIS")
                //console.log(price)
                let number = ((price).toString()).split('.');
                return number[0];
            },
            getOre(price){
                let number = ((price).toString()).split('.');
               // console.log("LENGTH");
                //console.log(number.length);
                if((number.length)==1){
                    return "00";
                }
                else{ //man vet at det er noen tall etter komma
                    
                    let ore = number[1].charAt(0) + number[1].charAt(1);

                    let oreString = '';

                    if(ore.length == 0){
                        oreString = ore + "00";
                    }
                    else if(ore.length == 1){
                        oreString = ore + "0";
                    }
                    else{
                        oreString = ore;
                    }
                    //console.log("OREEE start")
                    //console.log(ore)
                    //console.log("OREEE length")
                   // console.log(ore.length)
                    return oreString;
                }
                
            }
        }
}
</script>
<style scoped>
.left-arrow{
    display:none;
}
.left .left-arrow {
  width: 0; 
  height: 0; 
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent; 
  position:absolute;
  left:-20px;
  border-right:20px solid #3C3C3C; 
  display:block;
}
     .price{
        display:flex;
        align-items: center;
        color:#3C3C3C;
    }
    .dark .price{
         background: #3C3C3C;
         color:#FFFAD8;
         border-radius: 8px;
         padding:0 10px;
    }
    
    .label-kr,.ore{
        font-weight: 400;
        font-size:26px;
    }
    .label-kr{
        font-size:30px;
        
    }
    .kr{
            padding: 0px 12px 2px 10px;
        font-size: 58px;
        
    }
    .ore{
        margin-top:6px;
        line-height: 26px;
        

    }
    .ore .value{
        font-size: 31px;
        line-height: 21px;
    }
    .ore .label{
        font-size:23px;
    }
</style>
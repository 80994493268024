<template>
    <div class="wind-container">
        <img :src="imgSrc" /> 
        <div class="content">
            <div class="wind-speed">
                <div class="speed">{{speed}}</div>
                <div class="label">m/s</div>
            </div>
            <div class="description">{{wind.desc}} med {{gust}} m/s i kast.</div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default{
    
    data(){
        return{
            wind:{
                desc:'',
                icon:''
            },
            speed:'0',
            gust:'0',
            text:'',
            direction:'None',
            imgSrc:String
        }
    },
    props:{
       weather:{} 
    },
    methods: {
        getWeatherData(){
            axios
            .get('https://aainteractive.no/tibber-v2/cache/?name=weather')
            .then(response => {
                let currentWeather = response.data.predictions[0].data.instant.details;
                
                this.speed = Math.round(currentWeather.wind_speed);
                this.gust = Math.round(currentWeather.wind_speed_of_gust);
                this.direction = currentWeather.wind_from_direction;
                this.imgSrc = require('@/assets/wind_icons/'+(this.getWinDirection(this.direction))+'.svg')
                this.wind = this.getWindDescription(this.speed) 
            })
        },
        getWinDirection(deg){
            let dir = 'n';

            if(deg > 22.5 && deg < 67.5){
                dir = 'ne';
            }
            else if(deg > 67.5 && deg < 112.5){
                dir = 'e';
            }
            else if(deg > 112.5 && deg < 157.5){
                dir = 'se';
            }
            else if(deg > 157.5 && deg < 202.5){
                dir = 's';
            }
            else if(deg > 202.5 && deg < 247.5){
                dir = 'sw';
            }
            else if(deg > 247.5 && deg < 292.5){
                dir = 'w';
            }
             else if(deg > 292.5 && deg < 337.5){
                dir = 'nw';
            }
            else if(deg > 337.5){
                dir = 'n';
            }
            return dir;
        },
        getWindDescription(speed){
            let desc = 'Stille';
            let beaufort = '0'; //bruke til pil

            if(speed > 0.3 && speed <= 1.5){
                desc = 'Flau vind';
                beaufort = '1';
            }
            else if(speed > 1.5 && speed <= 3.3){
                desc = 'Svak vind';
                beaufort = '2';
            }
            else if(speed > 3.3 && speed <= 5.4){
                desc = 'Lett bris';
                beaufort = '3';
            }
            else if(speed > 5.4 && speed <= 7.9){
                desc = 'Laber bris';
                beaufort = '4';
            }
            else if(speed > 7.9 && speed <= 10.7){
                desc = 'Frisk bris';
                beaufort = '5';
            }
            else if(speed > 10.7 && speed <= 13.8){
                desc = 'Liten kuling';
                beaufort = '6';
            }
            else if(speed > 13.8 && speed <= 17.1){
                desc = 'Stiv kuling';
                beaufort = '7';
            }
            else if(speed > 17.1 && speed <= 20.7){
                desc = 'Sterk kuling';
                beaufort = '8';
            }
            else if(speed > 20.7 && speed <= 24.4){
                desc = 'Liten storm';
                beaufort = '9';
            }
            else if(speed > 24.4 && speed <= 28.4){
                desc = 'Full storm';
                beaufort = '10';
            }

            var windDesc = {
                desc: desc,
                beaufort:beaufort
            };

            return windDesc;
        }
    },
     created() {
        setInterval(() => this.getWeatherData(), 60000); //1000=sekund
    },
    mounted () {
        this.getWeatherData();
    }
}
</script>
<style scoped>
.wind-container{
    position:relative;

    height:250px;
    width:160px;
}
.wind-container img{
    position:absolute;
    left:0;
    top:0;
}
.wind-container .content{
    position:absolute;
    left:55px;
    top:60px;
}
.description{
    font-size:16px;
}
.wind-speed{
    display:flex;
    align-items: center;
}
.wind-speed .speed{
    font-size:38px;
}
.wind-speed .label{
    font-size: 21px;
    padding-left:7px;
}
.wind-speed .gust{

}
</style>
<template>
    <div :class="{small:small}"> <!--Small size-->
        <div :class="{dark:darkMode}">
            <div :class="{autoColor:autoColor}">
                <div :class="{twoLines:twoLines}"> 
                    <div class="temp">
                        <div class="label">{{label}}</div>
                        <div class="temp-and-circle">
                            <div class="value">
                                <div class="left">
                                    {{showTemp(temp,0)}}
                                </div>
                                <div class="center">
                                    <div style="">.</div>
                                </div>
                                <div class="right">
                                    <div class="after-comma">
                                         {{showTemp(temp,1)}}
                                    </div>
                                    <div class="circle"></div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props:{
        darkMode:Boolean,
        temp:Number,
        label:String,
        autoColor:Boolean,
        small:Boolean,
        twoLines:Boolean,
        showDecimals:Boolean
    },
    methods:{
        showTemp(temp,part){
            
            
                
                var num = temp;
                var digits = num.toString().split('.');
                return digits[part];
                

                
            
            //return Math.round(temp);
            
        }
    }
}
</script>
<style scoped>
    .temp{
        display:flex;
        align-items: flex-start;
        
    }
    .temp .label{
        font-size:26px;
        padding-right:10px;
    }
    .temp-and-circle{
        display:flex;
      
    }
    .temp .value{
        
        color:#3C3C3C;
        font-size:42px;
        
        margin-right:5px;

        display:flex;
    }
    .temp .value .left{
        margin-bottom:-5px;
        margin-top: -13px;
    }
    .temp .value .center{
        font-size: 24px;
        padding:0px 2px;
        display:flex;
        align-items: end;
    }
    .temp .value .right{
        font-size: 22px;
        display:flex;
        flex-direction: column-reverse;
        
        align-items: center;
        justify-content: space-between;
    }
    .right .after-comma{
        
    }
    
    .temp .circle{
        width:12px;
        height:12px;
        
        border:3px solid #3C3C3C;
        border-radius: 14px;
    }
    .autoColor .temp .value{
        color:#EE2A3F;
    }
    .autoColor .temp .circle{
        border-color:#EE2A3F;
    }

    .dark .temp .value{
        color:#FFFAD8;
    }
    .dark .temp .circle{
        border:4px solid #FFFAD8;
    }

    /*SMALL*/
    .small .temp .value{
    /*    font-size:45px;
        line-height: 30px;*/
    }
    .small .temp .circle{
        /*width:15px;
        height:15px;
        border-width: 3px;
        border-radius: 15px;*/
    }




    /*SWIM*/ 
    .twoLines .temp{
        
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .twoLines .temp .label{
        padding-bottom: 5px;
        font-size: 20px;
        text-transform: uppercase;   
        letter-spacing: 1px;
        padding-right: 10px;
    }
    .twoLines  .temp .value{
        
        color:#3C3C3C;
        font-size:36px;
        line-height: 38px;
        margin-right:5px;
    }
    .twoLines  .temp .circle{
        width:12px;
        height:12px;
        margin-top:4px;
        border:3px solid #3C3C3C;
        border-radius: 10px;
    }
</style>
<template>
    <div class="power">
        <Header :text="getHeading()" /> 
        
        <div class="graph"> <Graph :power="power" /> </div>
        
        <div class="prices-wrapper">
            <div class="prices">
                <div class="header">
                    <div class="label">Pris nå:</div>
                    <div class="now">{{currentHours}}</div>
                </div> 

                <div class="price" style="display:none">
                    <Price :price="currentPrice" /> 
                    <div class="arrow"></div>
                </div>
                <div class="items">
                
                    <div class="item" v-for="item in priceExamples" :key="item.title">
                        <div class="title">{{item.title}}</div>
                        <div class="price">  <Price :price="item.price" />  </div>
                    </div>
                </div>     
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment';
    import Price from '@/components/util/Price.vue';
    import Graph from '@/components/units/Graph.vue';
    import Header from '@/components/util/Header.vue';
    
    export default{
        data(){
            return{
                currentPrice:0.0,
                currentHours:String,
                priceExamples:{},
                power:{}
            }
        },
         components:{
            Header,
            Price,
            Graph
        },
        mounted () {
            this.getPower();
        },
        created() {
            setInterval(() => this.getPower(), 900000); //15 min.
        },
        methods:{
            getHeading(){
                moment.locale('nb')
                return "Strømpris "+moment().format('dddd');
            },
            getPower(){
            axios
                .get('https://aainteractive.no/tibber-v2/cache/?name=power')
                .then(response => {
          
                    this.currentPrice = response.data.prices.current.total;
                    this.currentHours = response.data.prices.current.startsAt;                
                    
                    let from = moment(this.currentHours).format('H:mm');
                    let to = moment(this.currentHours).add(1, 'hours').format('H:mm');
                    
                    this.currentHours = from +"–"+ to;
                    
                    this.priceExamples = response.data.price_examples;        
                    
                    //sette graph sin data
                    this.power = response.data;
            })
            }
        }
    }
</script>

<style scoped>
.power{
    margin-top:80px;
}
.prices-wrapper{
    display:flex;
    justify-content: space-around;
    margin-top:35px;
}
.prices{
    display:flex;
    align-items: center;
}
.prices .header{
   padding-right:20px;
}
.prices .header .label{
    font-size:36px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.prices .header .now{
    font-size:25px;
    letter-spacing: 1px;
}
.prices > .price{
    padding:0px 18px;
    border:2px solid #515049;
    background:#FFFDE3;
    margin:0 26px 0 48px;
    border-radius: 12px;
    position:relative;
}
.prices > .price .arrow{
    width: 0; 
    height: 0; 
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  
    border-left: 15px solid #515049;
    position:absolute;
    right:-15px;
    top:25px;
}
.prices .items{
    display:flex;
    justify-content: space-around;

    border:2px solid #515049;
    background:#FFFDE3;
    border-radius: 12px;
}
.prices .items .item{
     padding:0px 22px;
     display:flex;
     align-items: center;
}
.prices .items .item:nth-child(2){
    border-left:2px solid #515049;
    border-right:2px solid #515049;
}
.prices .items .item .title{
     font-size:36px;
     letter-spacing: 1px;
     text-transform: uppercase;
     padding-right:20px;
}
</style>

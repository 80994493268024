
import {InMemoryCache, ApolloClient,HttpLink} from '@apollo/client'
import {createApolloProvider} from '@vue/apollo-option'

import { WebSocketLink } from 'apollo-link-ws'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
const cache = new InMemoryCache();

const httpLink = new HttpLink({
    uri:"https://api.tibber.com/v1-beta/gql",
    headers: {
        Authorization: 'Bearer IgmncnUHAZlWNRz8Ub4f4KW9WEWaGvBTH6WLd1vMgnI'
    },
})

//const headersWebSockets = { Authorization: "Bearer IgmncnUHAZlWNRz8Ub4f4KW9WEWaGvBTH6WLd1vMgnI" };


// Create the subscription websocket link
const wsLink = new WebSocketLink({
    uri: 'wss://api.tibber.com/v1-beta/gql/subscriptions',
    options: {
      reconnect: true,
      timeout: 300,
      connectionParams: {
        token: 'IgmncnUHAZlWNRz8Ub4f4KW9WEWaGvBTH6WLd1vMgnI'
      },
    },
  })

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
    },
    wsLink,
    httpLink
  )

const client = new ApolloClient({
    cache:cache,
    link:link
});

export const provider = createApolloProvider({
    defaultClient:client
});

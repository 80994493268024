<template>
    <div class="garbage-container">
        <div class="can">
                <div class="item" v-for="can in cans" :key="can.id"> 
                    <div :class="can.code" class="item-container">                  
                        
                        <img :src="getImagePath(can.code)"  />
                        <svg xmlns="http://www.w3.org/2000/svg" width="65.18" height="96.48" viewBox="0 0 45.937 67.351">
                        <g id="Group_1318" data-name="Group 1318" transform="translate(-227.358 -696.293)">
                            <g id="Group_303" data-name="Group 303" transform="translate(210 696.802)" opacity="0.51">
                            <g id="Group_294" data-name="Group 294" transform="translate(17.501 0)">
                                <path id="Path_174" data-name="Path 174" d="M6119.868,402.867l-1.479-1.042-6.928.388-.485,1.454-10.892-1.454h-3.83l-1.018,3.385,6.762,2.545,4.63,1.188-2.836.824-5.867-.824-5.333,1.648-.629,2.327,1.721,1.406-1.092,1.236v1.309l.485,4.169-2.836.873v1.721l3.442,2.109,1.552,37.983s8.557,3.879,16.924,4.048,16.546-3.37,16.546-3.37l1.381-41.5,1.721.728,1.284-1.721-3.005-3.274,1.721-2.521-.655-3.2,3.9-1.456-.8-2.52-14.39-4.353Z" transform="translate(-6089.885 -401.824)" fill="#b4b4b4" stroke="#707070" stroke-width="1" opacity="0.352"/>
                        </g></g></g>
                        </svg>
                        
                        <div class="circle gray">
                            <div class="circle-container">
                                <div class="circle1"></div>
                                <div class="circle2"></div>
                                <span>{{can.code}}</span>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div class="text">
            {{formatTime(canDate.date)}}
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'

export default{
     data(){
        return{
            canDate:"",
            cans:{}
        }
    },
     methods: {
       formatTime(time) {
            //return moment(time).utcOffset('+0100').format('dddd Do MMMM')
            return moment(time).utcOffset('+0100').format('DoMM')
        },
        getImagePath(){            
            //return require('@/assets/weather_icons/svg/'+code+'.svg');
            return require('@/assets/can.png');
        }         
    },
    mounted () {
      axios
        .get('https://aainteractive.no/tibber-v2/cache/?name=garbage')
        .then(response => {
          
            this.cans = response.data.cans
            this.canDate = response.data.date;
            
            if(!response.data.labels){return;}
            
            //this.canType = response.data.labels;
          
        })
        .catch(error=>{
            console.log(error.response.data);
        })
      }
}
</script>
<style scoped>

        .circle{
            
            position:absolute;
            top:30px;
            width:100%;
            display:flex;
            justify-content: space-around;
        }
        .circle-container{
            position:relative;
            width:40px;
            height:40px;
        }
        .circle-container .circle1{
            background:#FFFDE3;
            border:1px solid #3C3C3C;
            width:40px;
            height:40px;
            border-radius:40px;
            position:absolute;
            top:0;
            left:0;
            z-index:10;
        }
        .circle-container .circle2{

            border:1px solid #3C3C3C;
            width:34px;
            height:34px;
            border-radius:34px;
            position:absolute;
            top:3px;
            left:3px;
            z-index:10;
        }

        .R.item-container .circle-container .circle2{
            background:#D0CEB5;
        }
        .P.item-container .circle-container .circle2{
            background:#D5EBBF;
        }
        .M.item-container .circle-container .circle2{
            background:#D9BF84;
        }
            

        .circle-container span{
            position:absolute;
            display:block;
            left:0;
            top:2px;
            z-index:30;
            font-size:24px;
            width:100%;
            text-align: center;
        }

    .can{
        display:flex;
    }
    .text{
        position:absolute;
        bottom:15px;
        left:-35px;
        letter-spacing: 0.2px;
      }
    .R #Path_174{
        fill:gray;
    }
    .M #Path_174{
        fill:#BC870D;
    }
    .P #Path_174{
        fill:green;
    }
    .item-container{
        position:relative;
    }
    .item-container  img{
        position:relative;

    }
    .item-container  svg{
        position: absolute;
        left:0;
        top:0;
    }
</style>
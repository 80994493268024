<template>
    <div class="border-top">
        <div class="circle-wrapper">
            <div class="circle"></div>
        </div>
    </div>

    <div class="heading">
        <Header :darkMode="false" text="VÆRET NESTE DØGN" /> 
    </div>

    <div class="border-bottom">
        <div class="circle-wrapper">
            <div class="circle"></div>
        </div>
    </div>

    <div class="border"></div>
    <div class="forecasts-wrapper">
        <div class="items">
            <div class="item" v-for="forecast in forecasts" :key="forecast.id">
                <div class="title">
                    <span class="description">{{forecast.period.title}}</span><span class="time">{{forecast.period.start}}-{{forecast.period.end}}</span>
                </div>
                <div class="content">
                    <div class="box">
                        <div class="section">
                            <div class="image">
                                <img :src="getImagePath(forecast.forecast.data.next_6_hours.summary.symbol_code)" />
                            </div>
                            <div class="temp">
                                <Temp :small="true" :darkMode = "false" :temp="forecast.forecast.data.instant.details.air_temperature" />
                            </div>
                            <div class="wind-icon">
                                <img style="display:none" src="@/assets/wind_icon.svg" />  
                            </div>
                            <div class="wind">
                                <div class="speed">{{Math.round(forecast.forecast.data.instant.details.wind_speed)}} ({{Math.round(forecast.forecast.data.instant.details.wind_speed_of_gust)}}) </div><div class="label">m/s</div>
                            </div>
                        </div>
                        <div class="section">
                            <div>
                                {{formatPrecipitation(forecast.forecast.data.next_6_hours.details.precipitation_amount_min,forecast.forecast.data.next_6_hours.details.precipitation_amount_max)}} <span>mm</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import moment from 'moment';
import axios from 'axios'
import Temp from '@/components/util/Temp.vue';
import Header from '@/components/util/Header.vue';
export default{
     data(){
        return{
            forecasts:{}
        }
    },
    components:{
        Temp, 
        Header
    },
    methods: {
        formatTime(time) {
            return moment(time).utcOffset('+0100').format('HH:mm')
        },
        getImagePath(code){            
            return require('@/assets/weather_icons/svg/'+code+'.svg');
        },
        formatPrecipitation(min,max){
            if(min == 0 && max == 0){
                return "0";
            }
            return min +" - "+max;
        }         
    },
    mounted () {
      axios
        .get('https://aainteractive.no/tibber-v2/cache/?name=weather')
        .then(response => {
            this.forecasts = response.data.forecasts;
        })
    }
}
</script>
<style scoped>
.border-top{
      
    padding:20px 0;
    height:150px;

    overflow: hidden;
    display:flex;
    justify-content: space-around;
}
.border-top .circle-wrapper{
    position:relative;
}
.border-top .circle{
    width:6000px;
    height:6000px;
    border-radius:6000px;
    background:transparent;
    border:1px solid #3C3C3C;
    opacity: 0.7;
    position:absolute;
    top:-5870px;
    left:-3000px;
}

.border-bottom{
    margin-top: -80px;
    padding:20px 0;
    height:150px;

    overflow: hidden;
    display:flex;
    justify-content: space-around;
}
.border-bottom .circle-wrapper{
      position:relative;
     }
.border-bottom .circle{
      width:6000px;
      height:6000px;
      border-radius:6000px;
      background:transparent;
      border:1px solid #3C3C3C;
      opacity: 0.7;
      position:absolute;
      top:-5870px;
      left:-3000px;
  }





.heading{
     margin-bottom:0px;
}
.forecasts-wrapper{
   justify-content: space-around;
   
    display:flex;
}
.items{
    display:flex;
     max-width: 1380px;
    width: 1380px;
    width:100%;
    max-width:100%;
}
    .item{
        flex:1;
        height:290px;
        padding:30px 0 40px 0;
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .items .item:nth-child(1){
      border-right:1px solid #3C3C3C; 
        transform: rotate(10deg);
        margin-top:-40px;
    }
    .items .item:nth-child(2){
       border-right:1px solid #3C3C3C;
        
        transform: rotate(5deg);
    }
    .items .item:nth-child(3){
         
        border-right:1px solid #3C3C3C;
        transform: rotate(-5deg);
    }
    .items .item:nth-child(4){
        
        transform: rotate(-10deg);
        margin-top:-40px;
    }
    .items .item:first-child{
        border-left:none;
    }
    .items .item:last-child{
        border-right:none;
    }
    .item .title{

    }
    .item .title .description{
        font-size: 24px;
        text-transform: uppercase;
        padding-right:15px;
        letter-spacing: 1px;
    }
    .item .content{
        display:flex;
    }
    .item .content .box{
        flex:1;
        display:flex;
        flex-direction: column;
        margin-top:40px;
    }
    .item .content .box .section
    
    { flex:1;
        display:flex;
        
        align-items: center;}
        .item .content .box .section:nth-child(2)
    
    {
        font-size:38px;
        text-align: center;
        padding:10px 0 0 0;
        display:flex;
        justify-content: space-around;
    }
    .item .content .box .section:nth-child(2) span{
        font-size: 21px;
    }
   
    .box .image img{
        width:82px;   
    }
    .box .temp{
        margin-right: 10px;
        
    }
    .box .wind-icon{
        padding:0 10px 0 20px;
    }
    .box .wind-icon img{
        width:35px;
        
    }
    .box .wind{
        font-size:38px;
        display:flex;
        align-items: center;
    }
    .box .wind .speed{
        padding:0 5px 0 0px;
    }
    .box .wind .label{
        font-size:21px;
    }


    .time{
        font-size: 20px;
    }
    
    
</style>
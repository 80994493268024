<template>
    <div class="footer-container">
        <div class="border-top">
          <div class="circle-wrapper">
            <div class="circle"></div>
          </div>
        </div>
        <div class="vintage">
        <svg xmlns="http://www.w3.org/2000/svg" width="129.567" height="59" viewBox="0 0 129.567 59">
  <g id="Group_1419" data-name="Group 1419" transform="translate(-619.433 -1044)">
    <path id="Path_812" data-name="Path 812" d="M14790.172-3251.356s3.3-3.629,7.293-3.024,4.77,4.687,8.676,5.443,6.168-3.447,6.168-3.447" transform="translate(-14170 4312.847)" fill="none" stroke="#fffad8" stroke-width="2"/>
    <g id="Ellipse_58" data-name="Ellipse 58" transform="translate(647 1044)" fill="none" stroke="#fffad8" stroke-width="1">
      <circle cx="17.5" cy="17.5" r="17.5" stroke="none"/>
      <circle cx="17.5" cy="17.5" r="17" fill="none"/>
    </g>
    <path id="Path_813" data-name="Path 813" d="M15294.323-3098.919l-12.6,4.639,12.6,2.245-12.6,4.415,12.6,2.694-12.6,4.115,12.6,2.161" transform="translate(-14624 4149)" fill="none" stroke="#fffad8" stroke-width="1"/>
    <line id="Line_222" data-name="Line 222" y2="26" transform="translate(666.5 1047.216)" fill="none" stroke="#fffad8" stroke-width="1"/>
    <text id="_2" data-name="2" transform="translate(712 1068)" fill="#fffad8" font-size="21" font-family="GTPressura, GT Pressura"><tspan x="-9.702" y="0">2</tspan></text>
    <text id="V" transform="translate(732 1068)" fill="#fffad8" font-size="21" font-family="GTPressura, GT Pressura"><tspan x="-10.521" y="0">V</tspan></text>
    <text id="k" transform="translate(718 1068)" fill="#fffad8" font-size="15" font-family="GTPressura, GT Pressura"><tspan x="-6.585" y="0">k</tspan></text>
    <path id="Polygon_8" data-name="Polygon 8" d="M2.5,0,5,11H0Z" transform="translate(738 1044)" fill="#fffad8"/>
    <line id="Line_223" data-name="Line 223" y1="18" transform="translate(740.5 1052.5)" fill="none" stroke="#fffad8" stroke-width="1"/>
    <path id="Path_814" data-name="Path 814" d="M15316.771-3105l-3.937,15.6,3.938-3.5v7.728h1.563l3.14-12.35-4.7,2.644,3.466-10.124h-3.466" transform="translate(-14624 4149)" fill="#fffad8"/>
    <path id="Polygon_9" data-name="Polygon 9" d="M2.5,0,5,9H0Z" transform="translate(696 1071) rotate(180)" fill="#fffad8"/>
    <g id="Ellipse_59" data-name="Ellipse 59" transform="translate(724 1078)" fill="none" stroke="#fffad8" stroke-width="1">
      <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
      <circle cx="12.5" cy="12.5" r="12" fill="none"/>
    </g>
    <text id="_1_5" data-name="1,5" transform="translate(745 1096)" fill="#fffad8" font-size="16" font-family="GTPressura, GT Pressura"><tspan x="-17.696" y="0">1,5</tspan></text>
    <text id="OCT_1845_42" data-name="OCT 1845–42" transform="translate(721 1098)" fill="#fffad8" font-size="17" font-family="GTPressura, GT Pressura" letter-spacing="0.027em"><tspan x="-91.97" y="0">OCT 1845–42</tspan></text>
    <line id="Line_224" data-name="Line 224" y2="26" transform="translate(663.5 1047.216)" fill="none" stroke="#fffad8" stroke-width="1"/>
  </g>
</svg>
</div>
    </div>
</template>

<script>

export default{
     data(){
        return{
            variable: "weather",
        }
    },
    components:{
       
    },
    props:{
        
    },
    methods: {
                
    },
    watch: {
    }
}
</script>
<style scoped>
.footer-container{
    padding:0;
      color:#FFFAD8;
      background:#3C3C3C;
      margin-top: -100px;
}
.border-top{
      
      padding:20px 0;
      height:150px;
      

      overflow: hidden;
       display:flex;
       justify-content: space-around;
    }
.border-top .circle-wrapper{
    position:relative;
}
.border-top .circle{
    width:6000px;
    height:6000px;
    border-radius:6000px;
    background:#FFFAD8;
    
    border:1px solid #FFFAD8;

    position:absolute;
    top:-5870px;
    left:-3000px;
}    
.vintage{
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;

}
.vintage svg{
    padding:5px;    
}
</style>
<template>
    
    <div class="sun-wrapper" :style="this.sunStyle">
        <div class="sun">
            <div class="sun-glow"></div>
            <div class="sun-disc"></div>
        </div>
    </div>

</template>

<script>import moment from 'moment';
  import axios from 'axios'
export default{   
        data(){
            return{
                sunUp:'',
                
                sunDown:'',
                sunStyle:'',
                initialBottom: 0,
                initialLeft: 0,
                items: [
                    { time: '9.18' }, 
                    { time: '10' }, 
                    { time: '11' }, 
                    { time: '12.1' }, 
                    { time: '12.78' }, 
                    { time: '13' }, 
                    { time: '14' }, 
                    { time: '15' },
                    { time: '16.42' }
                ]
            }
        },
    methods: {
        getSunStyleProd(){
            
            let gw = 1360;
            let gh = 680;

            var centerX = (gw * 0.5)-18,   // center of arc
                bottomY = gh -18,   // let center be a bit below horizon
                radiusX = gw *0.5, // radius, 80% of width in this example
                radiusY = gh *1 ;  // radius, 90% of height in this example

            // define sunrise and sunset times (in 24-hour clock, can be fractional)
            let sunrise = this.timeToDecimal(this.sunUp) //9.18;
            let sunset = this.timeToDecimal(this.sunDown);//16.42;
            
            let timeDecimal = this.timeToDecimal(new Date().getHours()+":"+new Date().getMinutes())
            //timeDecimal = this.timeToDecimal("12:49");

            var normTime = this.getTime2(timeDecimal,sunrise,sunset); 
            var angle = this.getAngle(normTime);
            
            var x = centerX + radiusX * Math.cos(angle);               
            var y = bottomY + radiusY * Math.sin(angle);
            //drawSunSingle();
            //this.calculateSunPosition();
            this.sunStyle = " right:"+(x)+"px;top:"+y+"px;position:absolute";
          //  return " right:"+(x)+"px;top:"+y+"px;";
        },
     
        timeToDecimal(t) {
            var arr = t.split(':');
            var dec = parseInt((arr[1]/6)*10, 10);

            return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
        },  

        getTime2(time,sunrise,sunset) {
            if (time > 23) time = 0;
            return (time - sunrise) / (sunset - sunrise);
        },
        getAngle(normTime) {
            return Math.PI + Math.PI * normTime
        }
    },
    props:{},
      mounted () {
        axios
            .get('https://aainteractive.no/tibber-v2/cache/?name=sun')
            .then(response => {
                //if(!response.data.sun_down){return;}
                let su = (response.data.sun_up)["@attributes"].time;
                let su_formatted  = moment(su).utcOffset('+0100').format('HH:mm')
                this.sunUp = su_formatted;

                let sd = (response.data.sun_down)["@attributes"].time;
                let sd_formatted  = moment(sd).utcOffset('+0100').format('HH:mm')
                this.sunDown = sd_formatted;

                
                this.getSunStyleProd();

            })
      },created() {
        setInterval(() => this.getSunStyleProd(), 1000);
    }
}
</script>

<style scoped>
    .sun-wrapper{
        position:absolute;
        width:42px;
        height:42px;
    }
    .sun{
        position:relative;      
    }
    .sun-disc{
         background:#FFFDE3;
        position:absolute;
        left:0;
        top:0;
        width:42px;
        height:42px;
        border:2px solid #3C3C3C;
        border-radius: 100%;
        z-index:100;
    }
    .sun-glow{
        position:absolute;
        width:120px;
        height:120px;
      
        border-radius: 120px;
        z-index: 50;
        left:-40px;
        top:-40px;  
        background: radial-gradient(#FFFDE3, #FFFDE3);
        background: radial-gradient(rgba(255,253,227,1),rgba(255,253,227,.5));
        
        opacity: .4;
    }
    .zenith-wrapper{
     
        display:flex;
        justify-content: space-around;
        
    }
    .zenith{

       display:flex;
       flex-direction: column;
       align-items: center;
       position:relative;
       margin-top:-30px;

    }
    .text{
        margin-bottom:2px;
    }
    .line{
        height:12px;
        width:2px;
        background:#3C3C3C;

    }
    
</style>
<template>


<Header :moon="moon" />  

      <!-- H O M E  -->
      <div class="section home"> 
        <Home  :home="home" :sun_and_moon="sun_and_moon" :sun="sun" :moon="moon" />  
      </div>

      <!-- P O W E R  -->
      <div class="section electricity">        
          <Power :power="electricity" />
      </div>
   
      <div class="section bottom">  

        <!-- W E A T H E R  -->
        <div class="weather">
          <Weather />
        </div>

        <!-- S W I M M I N G  -->
        <div class="footer"> 
          <Footer />
        </div>  
        
        <div class="footer2">
          <span style="">{{last_updated}}</span>
        </div>

      </div> 

</template>

<script>
  import axios from 'axios'
  import Home from './components/Home.vue'
  import Power from './components/Power.vue'
  import Weather from './components/Weather.vue'
  import Header from './components/Header.vue'
  import Footer from './components/Footer.vue'
  

  export default {
    name: 'App',
    components: {
      Home,
      Power,
      Weather,
      Header,
      Footer
    },
     data(){
      return{
          last_updated:String,
          dates:{},
          home:{},
          calendar:{},

          garbage:{},
          electricity:{},
          stores:{},
          sea:{},
          sun_and_moon:{},
          sun:{}, //noe knot med json
          moon:{}, //noe knot med json

         // weather:{},
          //swimming:{}

      }  
    },
    mounted () {
      axios
        .get('http://aainteractive.no/tibber-v1/api/getHomes.php')
        .then(response_homes => {
          let json = response_homes.data;
          
   //       this.lastupdated = json.last_updated; --> RYDD OPP DET ER TO LAST UPDATED!
          this.home = json.homes[0];
          //this.dates = json.dates;
          //this.calendar = json.calendar;

           axios
          .get('http://aainteractive.no/tibber-v1/api/getHomeDataCached.php?homeid=0')
          .then(response_home => {
            let json = response_home.data;

            this.moon = json.sun_and_moon.moon;
           // this.electricity = json.electricity;
           /* 
           this.last_updated = json.last_updated;
            
            this.garbage = json.garbage;
            this.stores = json.stores;
            this.sea = json.sea;
            this.sun_and_moon = json.sun_and_moon;
            this.sun = json.sun_and_moon.sun;
            
            this.moon = json.sun_and_moon.moon;*/
           //this.weather = json.weather;
           // this.swimming = json.swimming;
          });
        })
      }
    }//http://localhost/tibber/api/getHomeDataDummy.php?homeid=0
</script>

<style>
.border-top{
  margin-bottom:50px;
}
.screen{
  
}
.screen .content{
  
  position:relative;
  z-index:1000;
  
}
.screen .bg{
  position:absolute;
  z-index:500;
  width:400px;
  height:400px;
  
  bottom:0px;
}

  @font-face {
    font-family: "GT Pressura";
    src: url(./assets/fonts/GT-Pressura-Regular.woff) format("woff");
    font-weight:400;
  }
  @font-face {
    font-family: "Gt Pressura";
    src: url(./assets/fonts/GT-Pressura-Bold.woff) format("woff");
    font-weight:800;
  }
  *{
    box-sizing: border-box;
  }
  html,body{
    height:100%;
    padding:0;
    margin:0;
      background:#FFFAD8;
  }
  #app {
    font-family: Gt Pressura, Helvetica, Arial, sans-serif;
    font-weight: 400;
    color:#3C3C3C;
    
  /*  padding:20px;*/
    padding-bottom:0px;
    display:flex;
    flex-direction: column;
    
    height:100%;
    
  }

  
  .middle{
    flex:1;
    display:flex;
  }
  .middle .left{
    flex:4;
  }
  .middle .right{
    flex:2;
  }


  /*NYTT 18 JAN*/ 


    .home{
        margin-top:20px;
        padding:20px;
        position: relative;
        
    }
    .section.bottom{
      /*background:#3C3C3C;*/
      margin-top:160px;
     position:relative;
      
      flex:1;

      display:flex;
      flex-direction: column;
      
    }
   

    .weather{
     position:relative;
     z-index:100;
     overflow:hidden;
    
    }
    .footer{
      position:relative;
      z-index:50;
      flex:1; background:#3C3C3C;
    }
    .footer2{
      color:#FFFAD8;
      text-align: right;
      display:none;
    }/*
    .footer span{
      opacity: 0.6;
      padding:0 20px;
    }*/
    
  
  
  
</style>

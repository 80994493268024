

<template>

<div class="foreground">
    <Temp :showDecimals="true" :twoLines="true" :small="true" :darkMode = "false" :label="godalen.name" :temp="Number(godalen.temp)" /> 
</div>

<div class="background">
    <svg width="126.711" height="117.382" viewBox="0 0 126.711 117.382">
    <defs>
        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#e8e7ca"/>
        <stop offset="1" stop-color="#ededed" stop-opacity="0.09"/>
        </linearGradient>
    </defs>
    <g id="Group_1430" data-name="Group 1430" transform="translate(-23801.5 3285.984)">
        <g id="Group_1429" data-name="Group 1429">
        <g id="Group_1427" data-name="Group 1427" transform="translate(-8 30)">
            <g id="Group_1425" data-name="Group 1425" transform="translate(22542 -4037.513)">
            <g id="Group_1422" data-name="Group 1422">
                <g id="Group_1422-2" data-name="Group 1422">
                <g id="Group_1388" data-name="Group 1388" transform="translate(0.725)">
                    <path id="Path_805" data-name="Path 805" d="M17728.162-3293.09s-1.068-63.341-1.186-64.709c-.572-6.629-2.486-30.93.18-30.095,5.25,1.645,18.742,5.847,28.617,5.847,14.918,0,15.436-4.385,29.9-5.847s12.6,0,23.811,0,12.648-3.2,21.836-3.2c4.676,0,16.055,2.321,19.975,3.2,1.609.36.051,25.021-1.795,48.722s-5.582,46.082-5.582,46.082" transform="translate(-16459 4132)" fill="url(#linear-gradient)"/>
                </g>
                </g>
                <path id="Path_815" data-name="Path 815" d="M22711.957-3387.6c7.283.546,12.66,5.9,29.291,5.9s14.357-4.091,27.818-5.97,20.648.742,29.855,0,9.84-3.726,15.434-3.8,23.752,3.8,23.752,3.8" transform="translate(-21444 4132)" fill="none" stroke="#3c3c3c" stroke-width="1"/>
            </g>
            </g>
        </g>
        <g id="Group_1428" data-name="Group 1428">
            <path id="Path_173" data-name="Path 173" d="M17.069,13.319l-6.707,6.707a11.292,11.292,0,0,1,1.589.8,3.706,3.706,0,0,0,2.373.743,3.706,3.706,0,0,0,2.373-.743,8.03,8.03,0,0,1,4.52-1.321,7.894,7.894,0,0,1,4.5,1.321,3.874,3.874,0,0,0,2.373.743,3.747,3.747,0,0,0,2.373-.743c.248-.144.537-.31.846-.475L18.06,7.128C14.861,3.929,11.909,2.98,6.75,3V8.16c3.756-.021,5.964.8,8.255,3.1Z" transform="matrix(0.998, -0.07, 0.07, 0.998, 23843.365, -3286.793)" fill="#3c3c3c"/>
            <circle id="Ellipse_19" data-name="Ellipse 19" cx="5.214" cy="5.214" r="5.214" transform="matrix(0.998, -0.07, 0.07, 0.998, 23868.645, -3285.227)" fill="#3c3c3c"/>
        </g>
        </g>
    </g>
    </svg>

    <div class="line-hider"></div>
</div>

</template>
<script>
import Temp from '@/components/util/Temp.vue';

import axios from 'axios'
export default{
     data(){
        return{
            variable: String,
            godalen:{
                name:'Godalen',
                temp:0.0
            },
            swimmingLocations:{}
        }
    },
    props:{
        swimming:{}
    },
    components:{
        Temp
    },
    created() {
        setInterval(() => this.getData(), 600000); //1000=sekund //600 000=10 min
    },
    mounted () {
        this.getData();
    },
    methods:{
        getData(){
            axios
            .get('https://aainteractive.no/tibber-v2/cache/?name=swim')
            .then(response => {
                (response.data).forEach((location) => {
                    if(location.name == "Godalen"){   
                        this.godalen.temp = location.temp;
                    } 
                });
                this.swimmingLocations = response.data;
            })
            .catch(error=>{
                console.log(error.response.data);
            })
        }
    }
    }
</script>
<style scoped>
.locations-wrapper{
    margin-top:30px;
    justify-content: space-around;
    display:flex;display:none;
}
.background svg{
    z-index:20;
    position:relative;
}
.line-hider{
    height:5px;
    width:127px;
    background:#FFFAD8;
    position:absolute;
    top:20px;
    z-index:10;

}
.locations{
     max-width: 1000px;
    width: 1000px;
    display:flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.locations .location{
    width:33%;
    padding:20px 0px;
}
.foreground{
    position:absolute;
    top:40px;
    z-index: 30;
    left:25px;
}
</style>

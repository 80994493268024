<template>
    <div :class="{small:small}"> <!--Small size-->
        <div :class="{dark:darkMode}">
            <div :class="{autoColor:autoColor}">
                <div :class="{twoLines:twoLines}"> 
                    <div class="temp">
                        <div class="label">{{label}}</div>
                        <div class="temp-and-circle">
                            <div class="value">{{roundTemp(temp)}}</div>
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props:{
        darkMode:Boolean,
        temp:Number,
        label:String,
        autoColor:Boolean,
        small:Boolean,
        twoLines:Boolean,
        showDecimals:Boolean
    },
    methods:{
        roundTemp(temp){
            if(this.showDecimals){
                return temp;
            }
            //return Math.round(temp);
            return (temp);
        }
    }
}
</script>
<style scoped>
    .temp{
        display:flex;
        align-items: flex-start;
    }
    .temp .label{
        font-size:26px;
        padding-right:10px;
    }
    .temp-and-circle{
        display:flex;
    }
    .temp .value{
        
        color:#3C3C3C;
        font-size:40px;
        line-height: 30px;
        margin-right:5px;
    }
    
    .temp .circle{
        width:16px;
        height:16px;
        
        border:4px solid #3C3C3C;
        border-radius: 10px;
    }
    .autoColor .temp .value{
        color:#EE2A3F;
    }
    .autoColor .temp .circle{
        border:4px solid #EE2A3F;
    }

    .dark .temp .value{
        color:#FFFAD8;
    }
    .dark .temp .circle{
        border:4px solid #FFFAD8;
    }

    /*SMALL*/
    .small .temp .value{
        font-size:45px;
        line-height: 30px;
    }
    .small .temp .circle{
        width:15px;
        height:15px;
        border-width: 3px;
        border-radius: 15px;
    }




    /*SWIM*/ 
    .twoLines .temp{
        
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .twoLines .temp .label{
        padding-bottom: 5px;
        font-size: 20px;
        text-transform: uppercase;   
        letter-spacing: 1px;
        padding-right: 10px;
    }
    .twoLines  .temp .value{
        
        color:#3C3C3C;
        font-size:36px;
        line-height: 38px;
        margin-right:5px;
    }
    .twoLines  .temp .circle{
        width:12px;
        height:12px;
        margin-top:4px;
        border:3px solid #3C3C3C;
        border-radius: 10px;
    }
</style>
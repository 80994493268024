<template>
    <div :class="{dark:darkMode}">
        <div class="header-wrapper">
            <div class="header">
                <div class="left-part">
                    <div class="circle"></div>
                    <div class="lines">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>     
                </div>
                <div class="text">{{text}}</div>
                <div class="right-part">
                    <div class="lines">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                    <div class="circle"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props:{
        text:String,
        darkMode:Boolean
    }
}
</script>
<style scoped>
.header-wrapper{
    display:flex;
    justify-content: space-around;
}
.header{
    font-weight: 400;
    font-size:33px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    display:flex;
    align-items: center;
}
.header .text{
    padding:0 40px;
}
.left-part{
    display:flex;
}
.right-part{
    display:flex;
}
.circle{
    width:8px;
    height:8px;
    background:#3C3C3C;
    border-radius: 8px;
}
.left-part .circle{
    margin-right: 10px;
}
.right-part .circle{
    margin-left: 10px;
}
.lines{
    width:70px;
    
}
.lines .line:first-child{
    margin-bottom:5px;
}
.lines .line:nth-child(2){
    border-style: dashed;
    border-top:none;
    border-left:none;
    border-right:none;
    opacity: 0.9;
}
.lines .line{
    
    border-bottom:1px solid #3C3C3C;
    height:0px;
    
}
.dark .lines .line{
        border-bottom:1px solid #FFFAD8;
}
</style>
<template>
    <div class="temps">  
        <div class="outside">
            <Temp2 :showDecimals="true" :small="true" label="Ute:" :temp="tempOutside" :autoColor="true"  /> 
        </div>
        <div class="inside one">
            <Temp2 label="Stue:" :temp="tempSensor1" :autoColor="false"  /> 
            <div class="lines"></div>
        </div>
        <div class="inside two">
            <Temp2 label="Kjeller:" :temp="tempSensor2" :autoColor="false" /> 
            <div class="lines"></div>
        </div>
    </div>
</template>
<script>
  import axios from 'axios'
  //import Temp from '@/components/util/Temp.vue';
  import Temp2 from '@/components/util/Temp2.vue';
export default{
    data(){
        return{
            tempInside: 0.0,
            tempSensor1: 0.0,
            tempSensor2: 0.0,
            tempOutside: 0.0
        }
    },
    components: {
      //  Temp,
        Temp2
    }, 
    created() {
        setInterval(() => this.getData(), 600000); //1000=sekund //600 000=10 min
    },
    mounted () {
        this.getData();
    },
    methods:{
        getData(){
            axios
           .get('https://aainteractive.no/tibber-v2/cache/?name=netatmo')
            .then(response => {
                if(!response.data.body){return;}
                this.tempOutside = response.data.body.devices[0].modules[0].dashboard_data.Temperature; //utendørs
                this.tempSensor1 = response.data.body.devices[0].dashboard_data.Temperature;
                this.tempSensor2 = response.data.body.devices[0].modules[1].dashboard_data.Temperature; //kjeller
            })
            .catch(error=>{
                console.log(error.response.data);
            })
        }
    }
}
</script>
<style scoped>
    .temps{
        position: relative;
        height:300px;
        
    }
    .outside{
        margin-bottom:75px;
    }
    .inside{
        position:absolute;
    }
    .inside.one{
        bottom:90px;
        left:-10px;
    }
   
    .lines{
        width:106px;
        height:30px;
        position:absolute;
        bottom: -44px;
        left: -69px;
        border-right:1px dashed #3C3C3C;
        border-bottom:1px dashed #3C3C3C;
        opacity:0.7;
    }
 .inside.two{
        bottom:-100px;
        left:-90px;
    }
    .inside.two .lines{
        width:56px;
        height:60px;
        position:absolute;
        bottom: 15px;
        left: -75px;
        border-left:1px dashed #3C3C3C;
        border-right:0;
        border-bottom:1px dashed #3C3C3C;
        opacity:0.7;
    }
</style>